<template>
  <div class="content">
    <!-- 日志 -->
    <div class="logBox" v-if="!isLoad">
      <!-- 日志框框 -->
      <div
        class="logItem"
        v-for="(item, index) in logList"
        :key="index"
        @click="goLogDetail(item)"
      >
        <!-- 标签 -->
        <div class="label">党员:{{ item.supportName }}</div>
        <!-- 内容 -->
        <div>
          <div class="left">结对党员</div>
          <div class="right">{{ item.supportedName }}</div>
        </div>
        <div>
          <div class="left">帮扶内容</div>
          <div class="right">{{ item.content }}</div>
        </div>
        <div style="width: 100%; height: 1px; border-bottom: 1px solid #eeeeee"></div>
        <div>
          <div class="left">提交日期</div>
          <div class="right">{{ item.createTime.split(' ')[0] }}</div>
        </div>
        <div>
          <div class="left" style="margin-right: 33px">状态</div>
          <div class="right">{{ item.statusStr }}</div>
        </div>
        <div>
          <div class="left" style="margin-right: 33px">积分</div>
          <div class="right" v-if="item.status">{{ item.supportIntegral }}</div>
          <div class="right" v-else>-</div>
        </div>
      </div>
      <div class="noMore" v-if="!isLoad">没有更多了</div>
    </div>
    <!-- 加载 -->
    <div style="padding-top: 50%; text-align: center" v-if="isLoad">
      <van-loading size="24px">加载中...</van-loading>
    </div>
    <!-- 编辑日志按钮 -->
    <div class="btnBox" @click="goLogSave">编辑日志</div>
  </div>
</template>
<script>
import { requestLogList } from '@/api/pairSupport';
export default {
  data() {
    return {
      logList: [],
      isLoad: true,
    };
  },
  created() {
    this.getLogList();
  },
  methods: {
    // 获取日志列表
    getLogList() {
      requestLogList({ current: 1, size: 999 }).then((res) => {
        this.logList = res.data.records;
        this.isLoad = false;
      });
    },
    // 跳转至日志详情页面
    goLogDetail(item) {
      this.$router.push({ name: 'logDetail', params: { id: item.id } });
    },
    //跳转至编辑日志页面
    goLogSave() {
      this.$router.push({ name: 'logSave' });
    },
  },
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content { 
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  .logBox {
    background-color: #f5f5f5;
    padding: 32 * $px 32 * $px 140 * $px;
    box-sizing: border-box;
    .logItem {
      box-sizing: border-box;
      width: 100%;
      background: #fff;
      border-radius: 8 * $px;
      padding: 24 * $px;
      position: relative;
      margin-bottom: 24 * $px;
      font-size: 28 * $px;
      > div {
        display: flex;
        margin-bottom: 20 * $px;
      }
      .left {
        color: #999;
        margin-right: 10 * $px;
        white-space: nowrap;
      }
      .right {
        color: #333;
      }
      .label {
        position: absolute;
        top: 24 * $px;
        right: 24 * $px;
        font-size: 24 * $px;
        color: #fff;
        background: #d51616;
        padding: 4 * $px 8 * $px;
      }
    }
  }
  .noMore {
    text-align: center;
    font-size: 32 * $px;
    color: #999;
  }
  .btnBox {
    width: 642 * $px;
    height: 96 * $px;
    background: #1677ff;
    border-radius: 48 * $px;
    position: fixed;
    bottom: 50 * $px;
    left: 54 * $px;
    font-size: 36 * $px;
    color: #fff;
    text-align: center;
    line-height: 96 * $px;
  }
}
</style>
